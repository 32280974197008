#footer {
  background-color: #333333;
  color: $white;
  > .container {
    padding-top: 30px;
    // padding-bottom: 30px;
    @include media-breakpoint-up(lg) {
      padding-top: 45px;
      padding-bottom: 45px;
      display: grid;
      grid-template:
        "logo ... gnav"
        "info info info"
        / auto 1fr auto;
    }
    .logo {
      grid-area: logo;
      img {
        height: 36px;
        width: auto;
        @include media-breakpoint-up(lg) {
          height: 40px;
        }
      }
    }
    .info {
      font-size: 12px;
      grid-area: info;
    }
    .gnav {
      grid-area: gnav;
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          a {
            display: flex;
            border: none;
            border-right: 1px solid $white;
            padding: 0 40px;
            font-size: 15px;
            // font-weight: bold;
            text-decoration: none;
            color: $white;
            &:hover {
              text-decoration: none;
            }
          }
          &:last-child {
            a {
              border: none;
            }
          }
        }
      }
    }
  }
  .footerBottom {
    background-color: #333;
    color: $white;
    padding: 15px 0;
    font-size: 10px;
    @include media-breakpoint-up(sm) {
      padding: 20px 0;
      .container {
        display: flex;
        justify-content: space-between;
      }
    }
    .link {
      color: $white;
      text-decoration: none;
    }
    .copyright {
      margin: 0;
      margin-top: 5px;
      @include media-breakpoint-up(sm) {
        margin: 0;
      }
    }
  }
}
