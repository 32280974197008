.formTable {
  border-top: 1px solid $gray-300;
  font-size: 12px;
  width: 100%;
  dl {
    display: block;
    margin: 0;
    padding: 0;
    dt {
      display: block;
      font-weight: bold;
      text-align: left;
      padding: 15px 0;
      margin: 0;
      .badge {
        padding: 10px 15px;
        margin-right: 10px;
      }
    }
    dd {
      display: block;
      text-align: left;
      border-bottom: 1px solid $gray-300;
      padding: 0 0 15px;
      margin: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    font-size: 15px;
    display: table;
    dl {
      display: table-row;
      dt {
        display: table-cell;
        padding: 20px 0;
        width: 1%;
        white-space: nowrap;
        border-bottom: 1px solid $gray-300;
      }
      dd {
        display: table-cell;
        padding: 20px 0;
        padding-left: 20px;
        border-bottom: 1px solid $gray-300;
        .form-control,
        .form-select {
          margin: 0;
        }
      }
    }
  }
}
