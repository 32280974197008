.hero {
  width: 100%;
  height: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .catchcopy {
    margin: 0;
    position: relative;
    z-index: 2;
  }
  .scrollIcon {
    position: absolute;
    z-index: 2;
    bottom: 15px;
    left: 0;
    width: 100%;
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
