.brandContainer {
  padding: 0 6%;
}
.bandSpace {
  height: 90px;
  @include media-breakpoint-up(sm) {
    height: 120px;
  }
  @include media-breakpoint-up(lg) {
    height: 150px;
  }
}
.widelayout {
  padding: 90px 0;
  @include media-breakpoint-up(sm) {
    padding: 120px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 150px 0;
  }
}
.harf {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    .widelayout {
      flex: 1;
    }
  }
}
