.dataTable {
  display: table;
  border-top: 1px solid $gray-300;
  font-size: 12px;
  width: 100%;
  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  dl {
    display: table-row;
    dt {
      display: table-cell;
      font-weight: bold;
      padding: 20px 0;
      white-space: nowrap;
      border-bottom: 1px solid $gray-300;
      color: $navy;
      @include media-breakpoint-up(sm) {
        padding: 30px 0;
      }
    }
    dd {
      display: table-cell;
      padding: 20px 0;
      padding-left: 20px;
      border-bottom: 1px solid $gray-300;
      @include media-breakpoint-up(sm) {
        padding: 30px 0;
        padding-left: 10%;
      }
    }
  }
}
