.btn-more {
  min-width: 200px;
  position: relative;
  .icon-right {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-size: 10px;
  }
}
