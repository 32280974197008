.newsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px solid $gray-200;
    a {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      padding: 15px 0;
      transition: $transition-base;
      text-decoration: none;
      color: $body-color;
      @include media-breakpoint-up(sm) {
        padding: 20px 0;
      }
      &:hover {
        background-color: $gray-100;
      }
      .date {
        margin-right: 30px;
        color: $primary;
        font-weight: bold;
      }
      .title {
        flex: 1;
      }
    }
  }
}
