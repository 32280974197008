.cardLineup {
  display: flex;
  gap: 20px 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  .card {
    flex-basis: 100%;
    @include media-breakpoint-up(sm) {
      flex-basis: calc((100% / 3) - 20px);
    }
  }
}
