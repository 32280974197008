@use "sass:list";

$headerHeightSM: 60px;
$headerHeightLG: 100px;
#header {
  width: 100%;
  height: $headerHeightSM;
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  background-color: $navy;
  display: grid;
  grid-template:
    "... logo sm" #{$headerHeightSM}
    "gnav gnav gnav"
    / 20px 1fr 120px;
  @include media-breakpoint-up(lg) {
    height: $headerHeightLG;
    grid-template: "... logo gnav btnCTA ..." list.slash($headerHeightLG, 30px) auto auto 160px 30px;
  }
  .logo {
    grid-area: logo;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      height: 30px;
      width: auto;
      @include media-breakpoint-up(lg) {
        height: 40px;
      }
    }
  }
  .gnav {
    grid-area: gnav;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    &.active {
      display: block;
    }
    li {
      a {
        display: block;
        padding: 20px;
        border-top: 1px solid $primary;
        text-decoration: none;
        transition: $transition-base;
        background-color: $navy;
        font-size: 13px;
        color: $white;
        &:hover {
          text-decoration: none;
        }
      }
      &:last-child {
        a {
          border-bottom: 1px solid $primary;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &.active {
        display: flex;
      }
      li {
        a {
          display: flex;
          border: none;
          border-right: 1px solid $white;
          padding: 0 40px;
          font-size: 15px;
          font-weight: bold;
        }
        &:last-child {
          a {
            border: none;
          }
        }
      }
    }
  }
  .btn-cta {
    grid-area: btnCTA;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        color: $navy;
        .icon {
          margin: 0 5px;
        }
      }
    }
  }
  .headerRightSM {
    grid-area: sm;
    display: flex;
    align-items: stretch;
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .btn-cta-sm {
      width: $headerHeightSM;
      height: $headerHeightSM;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
    }
    .toggleMenuBtn {
      width: $headerHeightSM;
      height: $headerHeightSM;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #3f86c6;
      color: $white;
    }
  }
}
