.sectionHeader {
  text-align: center;
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 45px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
  .subtitle {
    font-size: 15px;
    line-height: 1.2;
    font-weight: normal;
    margin-top: 10px;
    letter-spacing: 0.1em;
    opacity: 0.5;
    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }
  .title {
    margin: 0;
    font-size: 30px;
    font-weight: normal;
    font-family: $font-family-en;
    line-height: 1.2;
    letter-spacing: 0.1em;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 48px;
    }
  }
}
