@use "sass:math";
@import "custom";
@import "~bootstrap/scss/bootstrap";
// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
html {
  font-size: 15px;
  font-feature-settings: "palt";
  letter-spacing: 0.08em;
  // visibility: hidden;
  // &.wf-active {
  //   visibility: visible;
  // }
}
[v-cloak] {
  display: none;
}
.slick-slider {
  opacity: 0;
  transition: all 1s ease;
}
.slick-slider.slick-initialized {
  opacity: 1;
}
img {
  max-width: 100%;
  height: auto;
}
#lead {
  background-color: #f2f2f2;
  padding: 90px 0;
  @include media-breakpoint-up(sm) {
    padding: 120px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 150px 0;
  }
  .title {
    font-size: 18px;
    line-height: 2;
    color: $primary;
    margin-bottom: 60px;
    br {
      display: none;
      @include media-breakpoint-up(sm) {
        display: inline;
      }
    }
    @include media-breakpoint-up(sm) {
      font-size: 24px;
      text-align: center;
    }
    @include media-breakpoint-up(lg) {
      font-size: 26px;
    }
  }
  .cont {
    margin: 0;
    text-align: center;
  }
}
#services {
  background-color: #04285c;
  color: $white;
}
#message {
  background-color: #333333;
  color: $white;
}
#about {
  background-color: #f2f2f2;
  .sectionHeader {
    .title {
      color: $navy;
    }
  }
}
#contact {
  background-color: rgba($color: $navy, $alpha: 0.07);
  .sectionHeader {
    .title {
      color: $navy;
    }
  }
}

@import "components/header";
@import "components/hero";
@import "components/layout";
// @import "components/thumbnailLink";
@import "components/sectionheader";
@import "components/cardLineup";
// @import "components/reading";
// @import "components/nav";
// @import "components/cta";
// @import "components/whiteBox";
// @import "components/sidemenu";
@import "components/dataTable";
@import "components/formTable";
// @import "components/wpblockstyles";
// @import "components/pageheader";
@import "components/newslist";
// @import "components/digest";
// @import "components/pagination";
@import "components/article";
// @import "components/summaryTable";
// @import "components/faq";
@import "components/btn";
// @import "components/icontextlink";
// @import "components/iconText";
@import "components/footer";
// @import "components/animation";
@import "u";
