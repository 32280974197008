.kome {
  font-size: 10px;
  padding-left: 1em;
  text-indent: -1em;
}
.lh-lg-responsive {
  line-height: 1.33 !important;
  @include media-breakpoint-up(sm) {
    line-height: 2 !important;
  }
}
.uppercase {
  text-transform: uppercase;
}
.font-en {
  font-family: $font-family-en;
}
.lead {
  line-height: 2;
  font-size: 15px;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
}
.marker {
  display: inline;
  position: relative;
  background: linear-gradient(rgba($warning, 1), rgba($warning, 1));
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100% 40%;
}
